import * as axios from 'axios';

const instance = axios.create({
  crossDomain: true,
  baseURL: process.env.VUE_APP_BASE_URI + '/newsletter',
  withCredentials: true,
  contentType: 'application/json',
});

export const apiClient = {
  saveNewsletter (data) {
    return instance.post('createNewsletter', data, {});
  },
};
